<template>
    <div :id="uppyId">
        <div class="ThumbnailContainer" v-if="collection === 'thumbnail'">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon :disabled="disabled" id="open-thumbnail-modal" class="mr-0" color="primary" text>
                        <v-icon>mdi-upload</v-icon>
                    </v-btn>
                </template>
                <span>Upload Files</span>
            </v-tooltip>
        </div>
        <div class="DashboardContainer" v-else></div>
    </div>
</template>

<script>
const Uppy = require('@uppy/core')
const Dashboard = require('@uppy/dashboard')
const XHRUpload = require('@uppy/xhr-upload')

export default {
    props: {
        modelClass: {
            type: String,
            required: true
        },
        modelId: {
            type: String,
            required: true
        },
        collection: {
            type: String,
            required: true
        },
        endpoint: {
            type: String,
            required: false,
            default() {
                return '/upload'
            }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            uppy: Uppy({
                id: this.uppyId,
                autoProceed: false,
                debug: false,
                restrictions: {},
                meta: {
                    modelClass: this.modelClass,
                    modelId: this.modelId,
                    collection: this.collection
                }
            })
        }
    },
    computed: {
        uppyId() {
            return this.modelClass + '-' + this.modelId + '-' + this.collection
        }
    },
    mounted() {
        this.$data.uppy.use(Dashboard, {
            trigger: '#open-thumbnail-modal',
            showLinkToFileUploadResult: false,
            showProgressDetails: true,
            hideCancelButton: false,
            closeAfterFinish: true,
            proudlyDisplayPoweredByUppy: false,
            resume: false,
            note: 'Folders with many files might take some time to appear. To upload a folder, drag it here',
            metaFields: [{ id: 'long_id', name: 'Long ID', placeholder: 'Long Names' }]
        })
        this.updateUppyXHR()
        this.$data.uppy.on('complete', result => {
            this.$data.uppy.reset()
            if (result.successful.length > 0) {
                const newFiles = result.successful.map(file => file.response.body)
                this.$store.dispatch('snapshotStore/updateLastUploadedFiles', {
                    newData: newFiles,
                    updateMode: 'add'
                })
                this.$store.dispatch('snapshotStore/fetchCurrentFiles', { snid: this.$route.params.snid, setFetchingStatus: true })
                this.$store.dispatch('snapshotStore/setShowUploadSnackbar', true)
            }
        })
    },
    watch: {
        endpoint() {
            this.updateUppyXHR()
        }
    },
    methods: {
        updateUppyXHR() {
            const plugin = this.$data.uppy.getPlugin('XHRUpload')
            if (plugin) this.$data.uppy.removePlugin(plugin)
            this.$data.uppy.use(XHRUpload, {
                endpoint: this.$axios.defaults.baseURL + this.endpoint,
                timeout: 600 * 1000,
                limit: 5,
                headers: {
                    Authorization: 'Bearer ' + this.$auth.accessToken
                },
                fieldName: 'file'
            })
        },
        handleLoginEvent(e) {
            if (e.loggedIn) {
                this.updateUppyXHR()
            }
        }
    }
}
</script>

<style src="@uppy/core/dist/style.css"></style>
<style src="@uppy/dashboard/dist/style.css"></style>
