var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: _vm.uppyId } }, [
    _vm.collection === "thumbnail"
      ? _c(
          "div",
          { staticClass: "ThumbnailContainer" },
          [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "mr-0",
                                attrs: {
                                  icon: "",
                                  disabled: _vm.disabled,
                                  id: "open-thumbnail-modal",
                                  color: "primary",
                                  text: ""
                                }
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-upload")])],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3365586356
                )
              },
              [_c("span", [_vm._v("Upload Files")])]
            )
          ],
          1
        )
      : _c("div", { staticClass: "DashboardContainer" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }